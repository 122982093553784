import Vue, { computed } from 'vue';
import Router, { type RawLocation, type Route } from 'vue-router';
import routeSwitch from '@/services/RouteSwitch';
import store from '@/store';
import VueGtag from 'vue-gtag';
import { getConfig } from '@/plugins/config';
import type { IUser } from '@/store/modules/auth/types';
import { getProductSlug, isDsatOrDpsat } from '@/helpers';

Vue.use(Router);

export const practiceRoutes = {
  path: '/practice',
  name: 'practice',
  redirect: '/practice/login',
  component: () => import('./views/Practice/Practice.vue'),
  meta: { public: true },
  children: [
    {
      path: 'login',
      name: 'practice-login',
      props: true,
      component: () => import('@/views/Practice/PracticeLogin.vue'),
      meta: { public: true },
    },
    {
      path: 'wait',
      name: 'practice-wait',
      props: true,
      component: () => import('@/views/Practice/PracticeWait.vue'),
      meta: { public: true },
    },
    {
      path: 'home-room',
      name: 'practice-home-room',
      props: true,
      component: () => import('@/views/Practice/PracticeHomeRoom.vue'),
      meta: { public: true },
    },
    {
      path: 'assessment/:subject?',
      name: 'practice-assessment',
      props: true,
      component: () => import('@/views/Practice/PracticeAssessment.vue'),
      meta: { public: true },
    },
    {
      path: 'assessment/review/:subject?',
      name: 'practice-assessment-review',
      props: true,
      component: () => import('@/views/Practice/PracticeAssessmentReview.vue'),
      meta: { public: true },
    },
  ],
};

export const reportsRoutes = {
  path: '/reports',
  name: 'reports',
  component: () => import('./views/Reports/Reports.vue'),
  children: [
    {
      path: 'timeline',
      name: 'timeline',
      component: () => import('@/views/Reports/Timeline.vue'),
    },
    {
      path: 'activity',
      name: 'activity',
      component: () => import('@/views/Reports/Activity.vue'),
    },
    {
      path: 'lesson-performance',
      name: 'lesson-performance',
      component: () => import('@/views/Reports/LessonPerformance.vue'),
    },
    {
      path: 'test-section-scores',
      name: 'test-section-scores',
      component: () => import('@/views/Reports/TestSectionScores.vue'),
    },
    {
      path: 'score-progression',
      name: 'score-progression',
      component: () => import('@/views/Reports/ScoreProgression.vue'),
    },
    {
      path: 'actionable-report',
      name: 'actionable-report',
      component: () => import('@/views/Reports/ActionableReport.vue'),
    },
    {
      path: 'printable',
      name: 'printable',
      redirect: '/reports/printable/actionable-adaptive-report/1',
      component: () => import('./views/Reports/Printable/PrintAbleReports.vue'),
      children: [
        {
          path: 'actionable-adaptive-report/:testNum',
          name: 'actionable-adaptive-report',
          component: () => import('@/views/Reports/Printable/ActionableAdaptiveReportPrintable.vue'),
        },
      ],
    },
  ],
};

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      component: () => import('./views/Auth/Login.vue'),
      meta: { public: true },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Auth/Login.vue'),
      meta: { public: true },
    },
    {
      path: '/expired',
      name: 'expired',
      component: () => import('./views/Expired.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('./views/Auth/RecoveryPassword.vue'),
      meta: { public: true },
    },
    {
      path: '/logout/:noredir?',
      name: 'logout',
      props: true,
      component: () => import('./views/Auth/Logout.vue'),
    },
    {
      path: '/home-room',
      name: 'home-room',
      component: () => import('@/views/Base/BaseHomeRoom.vue'),
    },

    {
      path: '/schedule/:modify?',
      name: 'schedule',
      props: true,
      component: () => import('./views/Schedule.vue'),
    },

    {
      path: '/sections',
      name: 'tsia2-sections',
      component: () => import('@/views/TSIA2/TSIA2Sections.vue'),
    },
    {
      path: '/exam-room/',
      name: 'exam-room',
      component: () => import('./views/ExamRoom/ExamRoom.vue'),
    },
    {
      path: '/study-hall/:subject?',
      name: 'study-hall',
      props: true,
      component: () => import('./views/Base/BaseStudyHall.vue'),
    },

    {
      path: '/vocabulary-builder',
      name: 'vocabulary-builder',
      redirect: '/vocabulary-builder/sort',
      component: () => import('./views/VocabularyBuilder/VocabularyBuilder.vue'),
      children: [
        {
          path: 'sort',
          name: 'vocabulary-builder-sort',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderSort.vue'),
        },
        {
          path: 'review',
          name: 'vocabulary-builder-review',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderReview.vue'),
        },
        {
          path: 'test',
          name: 'vocabulary-builder-test',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderTest.vue'),
        },
      ],
    },

    {
      path: '/wordsmith',
      name: 'wordsmith',
      redirect: '/wordsmith/sort',
      component: () => import('./views/VocabularyBuilder/VocabularyBuilder.vue'),
      children: [
        {
          path: 'sort',
          name: 'wordsmith-sort',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderSort.vue'),
        },
        {
          path: 'review',
          name: 'wordsmith-review',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderReview.vue'),
        },
        {
          path: 'test',
          name: 'wordsmith-test',
          component: () => import('@/views/VocabularyBuilder/VocabularyBuilderTest.vue'),
        },
      ],
    },
    {
      path: '/assessment/:subject?/:testNumber?/:sectionNumber?',
      name: 'assessment',
      props: true,
      component: () => import('./views/Base/BaseTestTake.vue'),
    },
    {
      path: '/session/:subject?/:questionNumber?/:forceInitSection?',
      name: 'assessment-react',
      props: true,
      component: () => import('./views/Base/BaseTestTake.vue'),
    },
    {
      path: '/courses-assessment/:courseType/:subject?/:testNumber?/:sectionNumber?/:grade?',
      name: 'courses-assessment',
      props: true,
      component: () => import('./views/Courses/Course.vue'),
    },
    {
      path: '/tsia2-assessment/:subject?/:testNumber?/:testType?',
      name: 'tsia2-assessment',
      props: true,
      component: () => import('./views/TSIA2/TSIA2Assessment.vue'),
    },
    {
      path: '/assessment/review/:subject?/:testNumber?/:sectionNumber?',
      name: 'assessment-review',
      props: true,
      component: () => import('./views/ExamRoom/AssessmentReview.vue'),
    },
    {
      path: '/review/:uSectionId/:questionNumber?',
      name: 'assessment-react-review',
      props: true,
      component: () => import('./views/ExamRoom/AssessmentReview.vue'),
    },
    {
      path: '/tsia2-assessment/review/:subject?/:testNumber?/:testType?',
      name: 'tsia2-assessment-review',
      props: true,
      component: () => import('./views/TSIA2/TSIA2AssessmentReview.vue'),
    },
    {
      path: '/courses-assessment/:courseType/review/:subject?/:testNumber?/:sectionNumber?/:grade?',
      name: 'courses-review',
      props: true,
      component: () => import('./views/Courses/CourseReview.vue'),
    },
    {
      path: '/fsa-assessment/review/:subject?/:testNumber?/:sectionNumber?/:grade?',
      name: 'course-fsa-review',
      props: true,
      component: () => import('./views/Courses/CourseReview.vue'),
    },

    {
      path: '/essay/:testNumber?',
      name: 'tsia2-essay',
      props: true,
      component: () => import('./views/TSIA2/TSIA2Essay.vue'),
    },

    {
      path: '/ksa-essay/:testNumber?',
      name: 'ksa-essay',
      props: true,
      component: () => import('./views/Courses/CoursesEssay.vue'),
    },
    {
      path: '/fsa-essay/:testNumber?',
      name: 'fsa-essay',
      props: true,
      component: () => import('./views/Courses/CoursesEssay.vue'),
    },

    {
      path: '/assessment-wait',
      name: 'assessment-wait',
      props: true,
      component: () => import('./views/ExamRoom/AssessmentWait.vue'),
    },

    {
      path: '/assessment-preview/:subject?/:testNumber?/:sectionNumber?',
      name: 'assessment-preview-test',
      props: true,
      component: () => import('./views/AssessmentPreview/Test.vue'),
    },

    {
      path: '/assessment-preview/review/:subject?/:testNumber?/:sectionNumber?',
      name: 'assessment-preview-review',
      props: true,
      component: () => import('./views/AssessmentPreview/Review.vue'),
    },

    {
      path: '/quizzes/:subject?',
      name: 'quizzes',
      component: () => import('./views/Quizzes/Quizzes.vue'),
    },
    {
      path: '/quiz/:subject?/:testNumber?',
      name: 'quiz',
      props: true,
      component: () => import('./views/Quizzes/Quiz.vue'),
    },
    {
      path: '/quiz/review/:subject?/:testNumber?',
      name: 'quiz-review',
      props: true,
      component: () => import('./views/Quizzes/QuizReview.vue'),
    },
    {
      path: '/graduation-planner/:menuItem?',
      name: 'graduation-planner',
      component: () => import('./views/GraduationPlanner/GraduationPlanner.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('./views/Profile.vue'),
    },
    reportsRoutes,
    {
      path: '/about/:page?',
      name: 'about',
      props: true,
      component: () => import('./views/About/About.vue'),
      meta: { public: true },
    },
    {
      path: '/sso',
      name: 'sso',
      component: () => import('./views/Auth/LoginSso.vue'),
      meta: { public: true },
    },
    practiceRoutes,
    {
      path: '/tsi',
      name: 'tsi',
      redirect: '/sections',
      component: () => import('./views/TSI/TSI.vue'),
      children: [
        {
          path: 'progress',
          name: 'tsi-progress',
          redirect: '/sections',
          props: true,
          component: () => import('@/views/TSI/TSIProgress.vue'),
        },
        {
          path: 'sections/:subject?/:level?',
          name: 'tsi-sections',
          redirect: '/sections',
          props: true,
          component: () => import('@/views/TSI/TSISections.vue'),
        },
        {
          path: 'study-hall/:subject?/:level?',
          name: 'tsi-study-hall',
          redirect: '/study-hall',
          props: true,
          component: () => import('@/views/TSI/TSIStudyHall.vue'),
        },
        {
          path: 'assessment/:subject?/:level?/:subtype?/:set?',
          name: 'tsi-assessment',
          redirect: '/sections',
          props: true,
          component: () => import('./views/TSI/TSIAssessment.vue'),
        },
        {
          path: 'essay/:level?/:set?',
          name: 'tsi-essay',
          redirect: '/sections',
          props: true,
          component: () => import('./views/TSI/TSIEssay.vue'),
        },
        {
          path: 'assessment/review/:subject?/:level?/:subtype?/:set?',
          name: 'tsi-assessment-review',
          redirect: '/sections',
          props: true,
          component: () => import('./views/TSI/TSIAssessmentReview.vue'),
        },
      ],
    },
    {
      path: '/assignments',
      name: 'qta',
      redirect: '/assignments',
      component: () => import('./views/Assignments/Assignments.vue'),
      children: [
        {
          path: '/',
          name: 'assignment-list',
          props: true,
          component: () => import('./views/Assignments/AssignmentList.vue'),
        },
        {
          path: ':uuid/content/:content_uuid',
          name: 'assignment',
          props: true,
          component: () => import('./views/Assignments/Assignment.vue'),
        },
      ],
    },
    {
      path: '/student/examroom',
      name: 'student-examroom',
      redirect: '/home-room',
    },
  ],
});

export async function beforeEachNewCall(to: Route, from: Route, next: (to?: RawLocation) => void): Promise<void> {
  try {
    // This is the entire problem with the scroll
    document.body.className = document.body.className.replace('modal-open', '');
  } catch (err) {}

  if (to.name === 'sso') {
    store.commit('authModule/setLogout');
  }

  const isPublic = to.matched.some((m) => m.meta.public);
  const isLogged = store.getters['authModule/isLogged'];

  if (!isPublic && !isLogged) {
    store.commit('authModule/setLogout');
  }

  if (to.name === 'logout' || (isPublic && !isLogged)) {
    next();
  } else if (isLogged) {
    await routeSwitch(store, router).next(to, from, next);
  } else {
    const theme = store.getters['theme'];
    if (theme.legacyUrl) {
      window.location.href = theme.legacyUrl;
    } else {
      window.location.href = getConfig().LegacyAddress;
    }
  }
}

// Added authentication guard.
router.beforeEach(beforeEachNewCall);

if (import.meta.env.MODE === 'production') {
  Vue.use(
    VueGtag,
    {
      config: { id: 'UA-171696861-1' },
    },
    router
  );
}

export default router;
